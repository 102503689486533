<template>
  <div v-if="isLoaded" style="heigh:100vh;">
    <Profile :user="user" :showSubscriptionOption="false"></Profile>
  </div>
  <div v-else >
    <v-skeleton-loader type="card-avatar, list-item"  class = "mx-auto my-10"
      max-height="60vh" max-width="60vw"></v-skeleton-loader>
  </div>
</template>

<script>
import Profile from '@/components/account/Profile.vue'
import { mapActions } from 'vuex'
import Vue from 'vue'
export default {
  components: {
    Profile
  },
  data () {
    return {
      isLoaded: false,
      user: { name: '' }
    }
  },
  methods: {
    ...mapActions('charityStore', ['containsCharity', 'getCharityByEmail', 'searchCharities'])
  },
  /**
   * Retrieve charity data from either local or backend account service
   */
  created () {
    const segment = Vue.$pathConverter.getLastSegmentOf(window.location.href)
    const input = {
      content: segment,
      category: ''
    }
    this.containsCharity(segment).then(yes => {
      if (yes) {
        // console.log(`Charity ${segment} has been cahced. Load it locally`)
        this.isLoaded = true
        this.getCharityByEmail(segment).then(charity => {
          this.user = charity
          // console.log(this.user)
        })
      } else {
        this.isLoaded = false
        this.searchCharities(input).then((response) => {
          if (response.data.length > 0) {
            this.user = response.data[0]
            // console.log(`Charity ${this.user.email} is loaded from backend Account Service`)
            this.isLoaded = true
          } else {
            throw new Error()
          }
        }).catch(() => {
          const firstSeg = Vue.$pathConverter.getFirstSegment()
          console.error(`The charity ${segment} is not found locally and at backend! Route to error page of ${firstSeg}`)
          this.$router.push({
            name: 'Error',
            params: { root: firstSeg }
          })
        })
      }
    })
  }
}
</script>
